import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { comClickSubMenu, errorMsgCheck, getCustomDataList } from '../../common/ComFunction';
import { getLoginMenuList } from '../../service/CommonService';
import { CUST_NO_HCL } from '../../common/ComValues';

var leftInit = true; // 최초 left 초기화 여부
var leftMenuList1 = [];
var leftMenuList2 = [];
var leftSubMenu = new Map();

export default class LeftMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingData: false, //Progress on/off
		};
	}
	activeProgress = (status) => {
		this.setState({
			loadingData: status,
		});
	};
	componentDidMount = () => {
		if (sessionStorage.authCode !== undefined && leftInit) {
			this.getMenuList();
			leftInit = false;
		}
	};
	getMenuList = () => {
		this.activeProgress(false);
		// 권한코드에 따른 메뉴조회
		let param = {
			authCode: sessionStorage.authCode,
			custNo: sessionStorage.custNo,
		};
		getLoginMenuList(param)
			.then((r) => {
				r.map((item, idx) => {
					if (item.level === '0') {
						leftMenuList1.push(item);
					} else if (item.level === '1') {
						leftMenuList2.push(item);
					}
				});
				// 메뉴코드에따른 서브메뉴 map 생성
				let list = [];
				let customData = JSON.parse(sessionStorage.getItem('customData'));

				leftMenuList1.map((item, idx) => {
					leftMenuList2.map((item2, idx2) => {
						if(item2.menuCode == '0000192' && !customData.hasOwnProperty('CT039')) { // 고객사 전용 승계기능사용여부
							return;
						}
						if (item.menuCode === item2.upprMenu) {
							// spotTitle에 따른 지점호칭변경(지점/대리점/현장)
							if (item2.menuName.indexOf('지점') > -1) {
								item2.menuName = item2.menuName.replace('지점', sessionStorage.spotTitle);
							}
							if (customData != null && customData.hasOwnProperty('CT030') && item2.menuName.indexOf('HC자산관리') > -1) {
								item2.menuName = item2.menuName.replace('HC', customData['CT030']);
							}
							if (item2.realMenu !== null) {
								list.push(item2);
							}
						}
					});
					leftSubMenu.set(item.menuCode, list);
					list = [];
				});

				this.activeProgress(false);
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => this.activeProgress(false));
			});
	};

	makeList = (mainPageId, subPageId) => {
		let list = leftSubMenu.get(mainPageId);
		let result = list.map((item, idx) => {
			// 20181227 kkj 메뉴얼은 http로 시작하므로 분기생성하여 새탭으로 열기
			if (item.menuPath.indexOf('http') > -1) {
				return (
					<li key={idx} onClick={() => window.open(item.menuPath, '_blank')}>
						<Link to={'#'}>{item.menuName}</Link>
					</li>
				);
			} else {
				return (
					<li key={idx} className={subPageId == item.menuCode ? 'active' : ''} onClick={() => this.clickSubMenu(item.menuCode)}>
						<Link to={item.menuPath}>{item.menuName}</Link>
					</li>
				);
			}
		});
		return <ul>{result}</ul>;
	};
	activeLeftMenu = () => {
		/*
            서버에서 가져오도록 수정
        */
		var mainPageId = sessionStorage.activePageId;
		var subPageId = sessionStorage.activeSubPageId;

		return this.makeList(mainPageId, subPageId);
	};

	clickSubMenu = (menuCode) => {
		getCustomDataList();
		comClickSubMenu(null, menuCode);
	};

	render() {
		let isLoggedIn = sessionStorage.accessToken !== undefined ? true : false;

		if (isLoggedIn && leftMenuList1.length > 0) {
			return <div>{this.activeLeftMenu()}</div>;
		} else {
			return <div></div>;
		}
	}
}
