import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

//요청 리스트 조회
export function getContOrderList(data) {
	return httpGet('/api/v1.0/cis/admin/contract/list' + toQueryString(data));
}

//요청제품 리스트 조회
export function getContOrderItemList(ordeNo) {
	return httpGet('/api/v1.0/cis/admin/contract/items/' + ordeNo);
}

//요청제품 쿠폰 리스트 조회
export function getContOrderItemCouponList(ordeNo) {
	return httpGet('/api/v1.0/cis/admin/contract/items/coupon/' + ordeNo);
}

//출고정보 저장
export function updateContOrderDelInfo(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/admin/contract-delivery/' + data.ordeNo, { body: data });
}

//배송/출고 정보 일괄 저장
export function updateContOrderDelInfoList(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/admin/contract-delivery/list/', { body: data });
}

//신청자ID 일괄 저장
export function updateContOrderRqstId(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/admin/contract/rqstId/', { body: data });
}

//제품정보 저장
export function updateContOrderItemInfo(data) {
	return httpPut('/api/v1.0/cis/admin/contract-item/' + data.ordeNo, { body: data });
}

//제품정보 일괄 저장
export function updateContOrderItemInfoList(data) {
	return httpPut('/api/v1.0/cis/admin/contract-item/list/', { body: data });
}

//제품수량변경
export function postChangeOrderQty(data) {
	return httpPost('/api/v1.0/cis/admin/contract-item/qty/' + data.ordeNo, { body: data });
}

//계약생성
export function contractProc(data) {
	return httpPost('/api/v1.0/cis/admin/contract/' + data.ordeNo, { body: data });
}

//계약일괄 생성 배치 등록
export function contractBatchProc(data) {
	return httpPost('/api/v1.0/cis/admin/contract/batch', { body: data });
}

//요청 삭제
export function deleteContOrder(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/admin/contract/' + data.ordeNo, { body: data });
}

//요청분리
export function divideOrde(data) {
	return httpPut('/api/v1.0/cis/admin/contract/divideOrde/', { body: data });
}

// 교원 계약정보 조회
export function getContInfoKY(data) {
	return httpPost('/api/v1.0/cis/admin/contract/contInfoKY/', { body: data });
}

// 교원 월별학습일수 조회
export function getKYUseageDays(data) {
	return httpPost('/api/v1.0/cis/admin/contract/kYUseageDays/', { body: data });
}

//계약일괄 체크건 처리상태 리셋
export function contractBatchStatusReset(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/admin/contract/statusReset/', { body: data });
}

//임의승인처리
export function provAppr(data) {
	return httpPut('/api/v1.0/cis/admin/contract/provAppr/' + data.ordeNo, { body: data });
}
