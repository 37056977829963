import React, { Component } from 'react';

import SaveIcon from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Transition, isEmpty } from '../../common/ComFunction';

import { updateAssetInfo, requestAssetTrans } from '../../service/rental/RentalAssetService';
import { errorMsgCheck, getByteLength } from '../../common/ComFunction';
import { CUST_NO_HKFIRE } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';

import { FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, AssetInfoChangePopStore } = CommonStore;

class AssetInfoChangePop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addrSeq: '',
			spotId: '',
			spotNm: '',
			userDeptId: '',
			userDeptNm: '',
			userNm: '',
			realUseSpot: '',
			remark: '',
			userEmpno: '',
			userTelno: '',
			userHpno: '',
			requestUserName: '',
			requestUserTelno: '',
			updateComplete: false,
			spotSelectRadio: '1'
		};
	}

	changeSave = () => {

		if(AssetInfoChangePopStore.procType == 'change') {
			if (
				this.state.spotId === '' &&
				this.state.spotNm === '' &&
				this.state.userDeptNm === '' &&
				this.state.userNm === '' &&
				this.state.realUseSpot === '' &&
				this.state.userEmpno === '' &&
				this.state.userTelno === '' &&
				this.state.userHpno === ''
			) {
				ModalStore.openModal('변경할 정보가 없습니다.');
				return;
			}
			if (sessionStorage.custNo === CUST_NO_HKFIRE && !this.state.userDeptId) {
				ModalStore.openModal('대리점 코드를 반드시 입력해 주세요.');
				return;
			}
			ConfirmModalStore.openModal('변경 하시겠습니까?', this.updateAssetInfo);
		} else if(AssetInfoChangePopStore.procType == 'trans') {
			/* 부서코드, 사용자명, 사용자사번, 전화번호, 
			세금계산서 담당자명, 핸드폰번호, 사유 */
			if (
				isEmpty(this.state.userDeptId.trim()) ||
				isEmpty(this.state.userNm.trim()) ||
				isEmpty(this.state.userEmpno.trim()) ||
				isEmpty(this.state.userTelno.trim()) ||
				isEmpty(this.state.requestUserName.trim()) ||
				isEmpty(this.state.requestUserTelno.trim()) ||
				isEmpty(this.state.remark.trim())
			) {
				ModalStore.openModal('* 표시된 필수값을 모두 입력해 주세요.');
				return;
			}
			ConfirmModalStore.openModal('승계 요청 하시겠습니까?', this.requestAssetTrans);
		}
	};

	updateAssetInfo = () => {
		ProgressStore.activeProgress();

		let param = {
			asstSeq: AssetInfoChangePopStore.asstInfo.asstSeq,
			asstNo: AssetInfoChangePopStore.asstInfo.asstNo,
			addrSeq: this.state.addrSeq,
			spotId: this.state.spotId,
			spotNm: this.state.spotNm,
			userDeptId: this.state.userDeptId,
			userDeptNm: this.state.userDeptNm,
			userNm: this.state.userNm,
			realUseSpot: this.state.realUseSpot,
			remark: this.state.remark,
			userId: sessionStorage.userId,
			userEmpno: this.state.userEmpno,
			userTelno: this.state.userTelno,
			userHpno: this.state.userHpno,
			requestUserName: this.state.requestUserName,
			requestUserTelno: this.state.requestUserTelno,
		};

		updateAssetInfo(param)
			.then((r) => {
				ModalStore.openModal('저장 되었습니다.');
				this.setState({ updateComplete: true });
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	requestAssetTrans = () => {
		ProgressStore.activeProgress();

		let param = {
			asstSeq: AssetInfoChangePopStore.asstInfo.asstSeq,
			asstNo: AssetInfoChangePopStore.asstInfo.asstNo,
			addrSeq: this.state.addrSeq,
			spotId: this.state.spotId,
			spotNm: this.state.spotNm,
			userDeptId: this.state.userDeptId,
			userDeptNm: this.state.userDeptNm,
			userNm: this.state.userNm,
			realUseSpot: this.state.realUseSpot,
			remark: this.state.remark,
			userId: sessionStorage.userId,
			userEmpno: this.state.userEmpno,
			userTelno: this.state.userTelno,
			userHpno: this.state.userHpno,
			requestUserName: this.state.requestUserName,
			requestUserTelno: this.state.requestUserTelno,
			spotInputType: this.state.spotSelectRadio,
			
		};

		requestAssetTrans(param)
			.then((r) => {
				ModalStore.openModal('요청 되었습니다.');
				this.setState({ updateComplete: true });
				this.popClose();
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setEmptyField = () => {
		this.setState({
			addrSeq: '',
			spotId: '',
			spotNm: '',
			userDeptId: '',
			userDeptNm: '',
			userNm: '',
			realUseSpot: '',
			remark: '',
			userEmpno: '',
			requestUserName: '',
			requestUserTelno: '',
		});
	};

	popClose = () => {
		AssetInfoChangePopStore.closePopup(this.state.updateComplete);
	};

	modalClearCallback = () => {
		this.setEmptyField(); //초기화
		this.popClose(this.state.updateComplete);
	};

	searchSpot = () => {
		CustSearchPopupStore.openPopup('2', null, AssetInfoChangePopStore.asstInfo.custNo, this.handleCustClose);
	};

	handleChange = (e, length) => {
		if (length != undefined && length != null && length > 0) {
			if (length < getByteLength(e.target.value)) {
				ModalStore.openModal(`해당값은 ${length}byte를 넘을 수 없습니다. (한글: 2byte, 숫자/영문: 1byte)`);
				return;
			}
		}
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});

		if (e.target.name === 'spotSelectRadio') {
			this.setState({
				spotId: '',
				spotNm: '',
			});
		}
	};

	handleCustClose = (item) => {
		if (item === undefined) return;
		this.setState({
			addrSeq: item.addrSeq,
			spotId: item.spotId,
			spotNm: item.spotNm,
		});
	};

	render() {
		return (
			<div>
				<Dialog open={AssetInfoChangePopStore.open} TransitionComponent={Transition} onClose={this.popClose} style={{marginTop:'-200px'}} >
					<div className="popSmArea searchPop">
						<div className="popCont" style={{ height: AssetInfoChangePopStore.procType == 'trans' ? 860 : 755, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>{AssetInfoChangePopStore.procType == 'change' ? '자산정보변경' : '자산승계요청'}</h2>
							</div>
							<p className="headline">현재정보</p>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 90 }} />
										<col style={{ width: 120 }} />
										<col style={{ width: 90 }} />
										<col style={{ width: 240 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}코드</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.spotId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.spotNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userDeptId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userDeptNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>사용자명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userNm} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>사용자사번</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userEmpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userTelno} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userHpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input type="text" value={AssetInfoChangePopStore.asstInfo.realUseSpot} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="headline">변경정보{AssetInfoChangePopStore.procType == 'trans' ? '' : ' - 변경되는 정보만 입력해 주세요' }</p>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 90 }} />
										<col style={{ width: 120 }} />
										<col style={{ width: 90 }} />
										<col style={{ width: 240 }} />
									</colgroup>
									<tbody>
										{AssetInfoChangePopStore.procType == 'change' ? '' :
											<tr>
												<th>입력방식</th>
												<td colSpan={3}>
													<FormControl component="fieldset" style={{ margin: '-11px 0px' }}>
														<RadioGroup
															aria-label="spotSelectRadio"
															name="spotSelectRadio"
															value={this.state.spotSelectRadio}
															onChange={(e) => {this.handleChange(e);}}
															row
														>
															<FormControlLabel value="1" control={<Radio />} label="선택입력" />
															<FormControlLabel value="2" control={<Radio />} label="직접입력" />
														</RadioGroup>
													</FormControl>
												</td>
											</tr>
										}
										<tr>
											<th>{sessionStorage.spotTitle}</th>
											<td colSpan="3">
												<input type="text" name="spotId" value={this.state.spotId} placeholder={sessionStorage.spotTitle + '코드'} readOnly={this.state.spotSelectRadio == '1'} style={{ width: 120 }} onChange={(e) => {this.handleChange(e, 15);}}/>
												<Button color="primary" variant="contained" size="small" style={{ marginLeft: 5, marginRight: 5 }} onClick={this.searchSpot} disabled={this.state.spotSelectRadio == '2'}>
													{sessionStorage.spotTitle}조회
												</Button>
												<input type="text" name="spotNm" value={this.state.spotNm} placeholder={sessionStorage.spotTitle + '명'} readOnly={this.state.spotSelectRadio == '1'} style={{ width: 220 }} onChange={(e) => {this.handleChange(e, 100);}}/>
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드{AssetInfoChangePopStore.procType == 'trans' ? <span className="star">*</span> : ''}</th>
											<td>
												<input
													name="userDeptId"
													type="text"
													value={this.state.userDeptId}
													placeholder={sessionStorage.deptTitle + '코드'}
													onChange={(e) => {this.handleChange(e, 20);}}
												/>
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input
													name="userDeptNm"
													type="text"
													value={this.state.userDeptNm}
													placeholder={sessionStorage.deptTitle + '명'}
													onChange={(e) => {this.handleChange(e, 100);}}
												/>
											</td>
										</tr>
										<tr>
											<th>사용자명{AssetInfoChangePopStore.procType == 'trans' ? <span className="star">*</span> : ''}</th>
											<td>
												<input name="userNm" type="text" value={this.state.userNm} onChange={(e) => {this.handleChange(e, 100);}} />
											</td>
											<th>사용자사번{AssetInfoChangePopStore.procType == 'trans' ? <span className="star">*</span> : ''}</th>
											<td>
												<input name="userEmpno" type="text" value={this.state.userEmpno} onChange={(e) => {this.handleChange(e, 15);}} />
											</td>
										</tr>
										<tr>
											<th>전화번호{AssetInfoChangePopStore.procType == 'trans' ? <span className="star">*</span> : ''}</th>
											<td>
												<input name="userTelno" type="text" value={this.state.userTelno} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input name="userHpno" type="text" value={this.state.userHpno} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input name="realUseSpot" type="text" value={this.state.realUseSpot} onChange={(e) => {this.handleChange(e, 200);}} />
											</td>
										</tr>
										{AssetInfoChangePopStore.procType == 'trans' ? 
										<tr>
											<th>세금계산서담당자명<span className="star">*</span></th>
											<td>
												<input name="requestUserName" type="text" value={this.state.requestUserName} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
											<th>세금계산서핸드폰번호<span className="star">*</span></th>
											<td>
												<input name="requestUserTelno" type="text" value={this.state.requestUserTelno} onChange={(e) => {this.handleChange(e, 20);}}/>
											</td>
										</tr>
										: ''}
										<tr>
											<th>변경사유{AssetInfoChangePopStore.procType == 'trans' ? <span className="star">*</span> : ''}</th>
											<td colSpan="3">
												<textarea
													name="remark"
													type="text"
													value={this.state.remark}
													rows="2"
													placeholder="변경 사유를 입력해 주세요"
													onChange={(e) => {this.handleChange(e, 500);}}
													style={{ height: 60 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.changeSave}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									{AssetInfoChangePopStore.procType == 'change' ? '저장' : '요청'}
								</Button>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(AssetInfoChangePop);
