import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getCisCommCode } from '../../service/CommonService';
import { getTransAssetList, updateAssetTrans, cancelAssetTrans, procAssetTrans, acceptAssetTrans } from '../../service/rental/RentalAssetService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, buildSelectItems, managerCheck, getByteLength, isEmpty } from '../../common/ComFunction';
import { CUST_NO_HKFIRE } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore,  } = CommonStore;

class AssetTransStatusManager extends Component {
	constructor(props) {
		super(props);

		this.sheet = null;
		this.sheetData = {
			id: 'AssetTransStatusManager', //sheet id
			initData: 'admin/Init-AssetTransStatusManager',
			width: '100%',
			height: '300px',
			sheetRendered: (sheet) => {
				this.sheet = sheet;
			},
		};
	}

	state = {
		startDate: moment(),
		endDate: moment(),
		sCustNo: '',
		sCustNm: '',
		sUserNm: '',
		sUserId: '',
		sOrdeNo: '',
		sChgStatus: '',
		chgStatusCdList: [], 

		chgAskNo: '',
		userTelno: '',
		userName: '',
		userEmpno: '',
		userDeptName: '',
		userDeptId: '',
		userHpno: '',
		spotNm: '',
		spotId: '',
		realUseSpot: '',
		chgUserTelno: '',
		chgUserName: '',
		chgUserEmpno: '',
		chgUserDeptName: '',
		chgUserDeptId: '',
		chgUserHpno: '',
		chgSpotNm: '',
		chgSpotId: '',
		chgRealUseSpot: '',
		requestUserName: '',
		requestUserTelno: '',
		rmk: '',
		spotSelectRadio: '',
		custNo: '',

		saveBtnDisabled: true,
		cancleBtnDisabled: true,
		procBtnDisabled: true,
		acceptBtnDisabled: true,

	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		//공통코드 조회
		Promise.all([getCisCommCode('31')])
			.then((r) => {
				let [code31Data] = r;
				this.setState({
					chgStatusCdList: code31Data, 
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
		});

		// sheet 이벤트 등록
		window[`${this.sheetData.id}_OnClick`] = (row, col) => {
			const sheet = window[this.sheetData.id];
			console.log(window[this.sheetData.id].GetRowData(row));

			this.setState({ 
				chgAskNo: sheet.GetCellValue(row, 'chgAskNo')
				,userTelno: sheet.GetCellValue(row, 'userTelno')
				,userName: sheet.GetCellValue(row, 'userName')
				,userEmpno: sheet.GetCellValue(row, 'userEmpno')
				,userDeptName: sheet.GetCellValue(row, 'userDeptName')
				,userDeptId: sheet.GetCellValue(row, 'userDeptId')
				,userHpno: sheet.GetCellValue(row, 'userHpno')
				,spotNm: sheet.GetCellValue(row, 'spotNm')
				,spotId: sheet.GetCellValue(row, 'spotId')
				,realUseSpot: sheet.GetCellValue(row, 'realUseSpot')
				,chgUserTelno: sheet.GetCellValue(row, 'chgUserTelno')
				,chgUserName: sheet.GetCellValue(row, 'chgUserName')
				,chgUserEmpno: sheet.GetCellValue(row, 'chgUserEmpno')
				,chgUserDeptName: sheet.GetCellValue(row, 'chgUserDeptName')
				,chgUserDeptId: sheet.GetCellValue(row, 'chgUserDeptId')
				,chgUserHpno: sheet.GetCellValue(row, 'chgUserHpno')
				,chgSpotNm: sheet.GetCellValue(row, 'chgSpotNm')
				,chgSpotId: sheet.GetCellValue(row, 'chgSpotId')
				,chgRealUseSpot: sheet.GetCellValue(row, 'chgRealUseSpot')
				,requestUserName: sheet.GetCellValue(row, 'requestUserName')
				,requestUserTelno: sheet.GetCellValue(row, 'requestUserTelno')
				,rmk: sheet.GetCellValue(row, 'rmk')
				,saveBtnDisabled: sheet.GetCellValue(row, 'chgStatus') == 'CA001' ? false : true
				,cancleBtnDisabled: sheet.GetCellValue(row, 'chgStatus') == 'CA001' ? false : true
				,procBtnDisabled: sheet.GetCellValue(row, 'chgStatus') == 'CA002' ? false : true
				,acceptBtnDisabled: sheet.GetCellValue(row, 'chgStatus') == 'CA001' ? false : true
				,spotSelectRadio: sheet.GetCellValue(row, 'spotInputType')
				,custNo: sheet.GetCellValue(row, 'custNo')
			});
			;
		};
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ startDate: date });
		} else {
			this.setState({ endDate: date });
		}
	};

	handleChange = (e, length) => {
		if (length != undefined && length != null && length > 0) {
			if (length < getByteLength(e.target.value)) {
				ModalStore.openModal(`해당값은 ${length}byte를 넘을 수 없습니다. (한글: 2byte, 숫자/영문: 1byte)`);
				return;
			}
		}
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});

		if (e.target.name === 'spotSelectRadio') {
			this.setState({
				chgSpotNm: '',
				chgSpotId: '',
			});
		}
	};

	searchCustomer = () => {
		//관리자만 조회할 수 있다.
		//if(sessionStorage.authCode <= 1){
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
		//}
	};

	searchUser = () => {
		UserPopupStore.openPopup(null, sessionStorage.authCode > 1 ? sessionStorage.custNo : '', this.handleUserSearch);
	};

	searchSpot = () => {
		CustSearchPopupStore.openPopup('2', null, this.state.custNo, this.handleSpotClose);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sUserId: item.userId,
				sUserNm: item.userName,
			});
		}
	};

	getSearchParam = () => {
		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}

		let param = {
			custNo: this.state.sCustNo,
			userId: this.state.sUserId,
			chgStatus: this.state.sChgStatus,
			startDate: dateToFormat(this.state.startDate),
			endDate: dateToFormat(this.state.endDate),
			ordeNo: this.state.sOrdeNo,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
		};

		return param;
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	handleSpotClose = (item) => {
		if (item === undefined) return;
		this.setState({
			addrSeq: item.addrSeq,
			chgSpotId: item.spotId,
			chgSpotNm: item.spotNm,
		});
	};

	searchList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();

		getTransAssetList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				this.setState({
					custNo: '', //고객번호
					ordeNo: '',
					appList: [],
					delStatus: '',
					activeStep: '',
				});
				//인풋필드 초기화
				this.setEmptyInput();
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setEmptyInput = () => {
		this.setState({
			chgAskNo: '',
			userTelno: '',
			userName: '',
			userEmpno: '',
			userDeptName: '',
			userDeptId: '',
			userHpno: '',
			spotNm: '',
			spotId: '',
			realUseSpot: '',
			chgUserTelno: '',
			chgUserName: '',
			chgUserEmpno: '',
			chgUserDeptName: '',
			chgUserDeptId: '',
			chgUserHpno: '',
			chgSpotNm: '',
			chgSpotId: '',
			chgRealUseSpot: '',
			requestUserName: '',
			requestUserTelno: '',
			rmk: '',
			saveBtnDisabled: true,
			cancleBtnDisabled: true,
			procBtnDisabled: true,
			acceptBtnDisabled: true,
			spotSelectRadio: '',
			custNo: '',
		});
	};

	saveAssetTrans = () => {

		if (
			isEmpty(this.state.chgUserDeptId.trim()) ||
			isEmpty(this.state.chgUserName.trim()) ||
			isEmpty(this.state.chgUserEmpno.trim()) ||
			isEmpty(this.state.chgUserTelno.trim()) ||
			isEmpty(this.state.requestUserName.trim()) ||
			isEmpty(this.state.requestUserTelno.trim()) ||
			isEmpty(this.state.rmk.trim())
		) {
			ModalStore.openModal('* 표시된 필수값을 모두 입력해 주세요.');
			return;
		}

		if (sessionStorage.custNo === CUST_NO_HKFIRE && !this.state.chgUserDeptId) {
			ModalStore.openModal('대리점 코드를 반드시 입력해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('변경정보를 수정 하시겠습니까?', () => {
			ProgressStore.activeProgress();
			let params = {
				chgAskNo: this.state.chgAskNo,
				chgUserTelno: this.state.chgUserTelno,
				chgUserName: this.state.chgUserName,
				chgUserEmpno: this.state.chgUserEmpno,
				chgUserDeptName: this.state.chgUserDeptName,
				chgUserDeptId: this.state.chgUserDeptId,
				chgUserHpno: this.state.chgUserHpno,
				chgSpotNm: this.state.chgSpotNm,
				chgSpotId: this.state.chgSpotId,
				chgRealUseSpot: this.state.chgRealUseSpot,
				requestUserName: this.state.requestUserName,
				requestUserTelno: this.state.requestUserTelno,
				rmk: this.state.rmk,
				spotInputType: this.state.spotSelectRadio,

			}
			updateAssetTrans(params)
				.then((r) => {
					ModalStore.openModal('수정되었습니다.', this.searchList);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	}
	cancleAssetTrans = () => {
		ConfirmModalStore.openModal('승계요청을 취소 하시겠습니까?', () => {
			ProgressStore.activeProgress();
			let params = {
				chgAskNo: this.state.chgAskNo,
			}
			cancelAssetTrans(params)
				.then((r) => {
					ModalStore.openModal('취소되었습니다.', this.searchList);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	}

	procAssetTrans = () => {
		ConfirmModalStore.openModal('승계처리 하시겠습니까?', () => {
			ProgressStore.activeProgress();
			let params = {
				chgAskNo: this.state.chgAskNo,
			}
			procAssetTrans(params)
				.then((r) => {
					ModalStore.openModal('승계완료되었습니다.', this.searchList);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	}

	acceptAssetTrans = () => {
		ConfirmModalStore.openModal('접수처리 하시겠습니까?', () => {
			ProgressStore.activeProgress();
			let params = {
				chgAskNo: this.state.chgAskNo,
			}
			acceptAssetTrans(params)
				.then((r) => {
					ModalStore.openModal('접수되었습니다.', this.searchList);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	}

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>승계요청현황</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchList}>
										<span>조회</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '140px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '120px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 235 }} value={this.state.sCustNm} />
												<IconButton onClick={this.searchCustomer} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>신청자</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ width: 100, marginRight: 10 }}
													value={this.state.sUserId}
													onChange={(e) => this.setState({ sUserId: e.target.value })}
												/>
												<IconButton onClick={this.searchUser} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
												<input
													type="text"
													style={{ width: 80, marginLeft: 10 }}
													value={this.state.sUserNm}
													onChange={(e) => this.setState({ sUserNm: e.target.value })}
												/>
											</td>
										</tr>
										<tr>
											<th>신청일자</th>
											<td>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.startDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.endDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>발주번호</th>
											<td>
												<input type="text" style={{ width: 130 }} value={this.state.sOrdeNo} onChange={(e) => this.setState({ sOrdeNo: e.target.value })} />
											</td>
											<th>진행상태</th>
											<td>
												<select name="sChgStatus" value={this.state.sChgStatus} onChange={this.handleChange}>
													<option value="">전체</option> 
													{this.state.chgStatusCdList ? buildSelectItems(this.state.chgStatusCdList) : ''}
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline" style={{display: 'inline'}}>승계리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '49%' }}>
								<p className="headline" style={{display: 'inline'}}>현재정보</p>
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}코드</th>
											<td>
												<input type="text" value={this.state.spotId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input type="text" value={this.state.spotNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드</th>
											<td>
												<input type="text" value={this.state.userDeptId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input type="text" value={this.state.userDeptName} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>사용자명</th>
											<td>
												<input type="text" value={this.state.userName} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>사용자사번</th>
											<td>
												<input type="text" value={this.state.userEmpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" value={this.state.userTelno} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input type="text" value={this.state.userHpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input type="text" value={this.state.realUseSpot} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '49%' }}>
								<p className="headline" style={{display: 'inline'}}>변경정보</p>
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>입력방식</th>
											<td colSpan={3}>
												<FormControl component="fieldset" style={{ margin: '-11px 0px' }}>
													<RadioGroup
														aria-label="spotSelectRadio"
														name="spotSelectRadio"
														value={this.state.spotSelectRadio}
														onChange={(e) => {this.handleChange(e);}}
														row
													>
														<FormControlLabel value="1" control={<Radio />} label="선택입력" />
														<FormControlLabel value="2" control={<Radio />} label="직접입력" />
													</RadioGroup>
												</FormControl>
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.spotTitle}</th>
											<td colSpan="3">
												<input type="text" name="chgSpotId" value={this.state.chgSpotId} placeholder={sessionStorage.spotTitle + '코드'} readOnly={this.state.spotSelectRadio == '1'} style={{ width: 120 }} onChange={(e) => {this.handleChange(e, 15);}}/>
												<Button color="primary" variant="contained" size="small" style={{ marginLeft: 5, marginRight: 5 }} onClick={this.searchSpot} disabled={this.state.spotSelectRadio == '2'}>
													{sessionStorage.spotTitle}조회
												</Button>
												<input type="text" name="chgSpotNm" value={this.state.chgSpotNm} placeholder={sessionStorage.spotTitle + '명'} readOnly={this.state.spotSelectRadio == '1'} style={{ width: 220 }} onChange={(e) => {this.handleChange(e, 100);}}/>
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드<span className="star">*</span></th>
											<td>
												<input
													name="chgUserDeptId"
													type="text"
													value={this.state.chgUserDeptId}
													placeholder={sessionStorage.deptTitle + '코드'}
													onChange={(e) => {this.handleChange(e, 20);}}
												/>
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input
													name="chgUserDeptName"
													type="text"
													value={this.state.chgUserDeptName}
													placeholder={sessionStorage.deptTitle + '명'}
													onChange={(e) => {this.handleChange(e, 100);}}
												/>
											</td>
										</tr>
										<tr>
											<th>사용자명<span className="star">*</span></th>
											<td>
												<input name="chgUserName" type="text" value={this.state.chgUserName} onChange={(e) => {this.handleChange(e, 100);}} />
											</td>
											<th>사용자사번<span className="star">*</span></th>
											<td>
												<input name="chgUserEmpno" type="text" value={this.state.chgUserEmpno} onChange={(e) => {this.handleChange(e, 15);}} />
											</td>
										</tr>
										<tr>
											<th>전화번호<span className="star">*</span></th>
											<td>
												<input name="chgUserTelno" type="text" value={this.state.chgUserTelno} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input name="chgUserHpno" type="text" value={this.state.chgUserHpno} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input name="chgRealUseSpot" type="text" value={this.state.chgRealUseSpot} onChange={(e) => {this.handleChange(e, 200);}} />
											</td>
										</tr>
										<tr>
											<th>세금계산서담당자명<span className="star">*</span></th>
											<td>
												<input name="requestUserName" type="text" value={this.state.requestUserName} onChange={(e) => {this.handleChange(e, 20);}} />
											</td>
											<th>세금계산서핸드폰번호<span className="star">*</span></th>
											<td>
												<input name="requestUserTelno" type="text" value={this.state.requestUserTelno} onChange={(e) => {this.handleChange(e, 20);}}/>
											</td>
										</tr>
										<tr>
											<th>변경사유<span className="star">*</span></th>
											<td colSpan="3">
												<textarea
													name="rmk"
													type="text"
													value={this.state.rmk}
													rows="2"
													placeholder="변경 사유를 입력해 주세요"
													onChange={(e) => {this.handleChange(e, 500);}}
													style={{ height: 60 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ clear: 'both', marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
								<Button
									color="primary"
									variant="outlined"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									disabled={this.state.saveBtnDisabled}
									onClick={this.saveAssetTrans}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									저장
								</Button>
								<Button
									color="primary"
									variant="outlined"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									disabled={this.state.acceptBtnDisabled}
									onClick={this.acceptAssetTrans}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									접수
								</Button>
								<Button
									color="secondary"
									variant="outlined"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									disabled={this.state.cancleBtnDisabled}
									onClick={this.cancleAssetTrans}
								>
									<DeleteIcon style={{ fontSize: 20, marginRight: 5 }} />
									취소
								</Button>
								<Button
									color="primary"
									variant="outlined"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									disabled={this.state.procBtnDisabled}
									onClick={this.procAssetTrans}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									승계처리
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(AssetTransStatusManager);
