import React, { Component } from 'react';
import { Link, Router, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/AccountCircle';
import ContIcon from '@material-ui/icons/LaptopMac';
import AppIcon from '@material-ui/icons/AddAlert';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ajLogo from '../../images/h1Logo.png';
import { getCustomDataList, comClickSubMenu, errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';
import { createBrowserHistory } from 'history';
import { getLoginMenuList, getHeaderBadgeCount } from '../../service/CommonService';
import { CUST_NO_HDENG, CUST_NO_HDEC, CUST_NO_HAS, CUST_NO_DDI, CUST_NO_HC, CUST_NO_HCL, CUST_NO_KMI } from '../../common/ComValues';

const browserHistory = createBrowserHistory();

var menuList = [];
var menuList1 = [];
var menuList2 = [];
var subMenu = new Map();
const LINE_V = 6;
const LINE_H = 5; // 메뉴는 4X3 구조로 뿌림
var headerInit = true; // 최초 헤더 초기화 여부

var badgeList = [];

export default class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			subMenuTitle: 'none',
			subMenuBg: 'none',
			menuCode: '',

			userName: '',
			userId: '',
			compCode: '',
			compName: '',

			// confirm open
			open: false,

			loadingData: false, //Progress on/off

			custImg: sessionStorage.custImg, // 이미지url
		};
	}
	activeProgress = (status) => {
		this.setState({
			loadingData: status,
		});
	};
	componentDidMount = () => {
		menuList = [
			/*
                서버에서 가져오도록 수정
            */

			{ menuCode: 0, menuName: '홈' },
			{ menuCode: 1, menuName: '렌탈' },
			{ menuCode: 2, menuName: '고객사전용' },
			{ menuCode: 3, menuName: '관리자' },
			{ menuCode: 4, menuName: '회원관리' },
			{ menuCode: 5, menuName: '포인트' },
		];
		if (sessionStorage.authCode !== undefined && headerInit) {
			this.getMenuList();
			headerInit = false;
		}
		//배지카운트 조회
		this.getAjBadgeCnt();
	};

	getAjBadgeCnt = () => {
		let param = {};

		if (sessionStorage.authCode < 2) {
			param = {
				badgeType: 'AJ',
				empno: sessionStorage.empno,
				menuCode1: '0000016',
				menuCode2: '0000017',
				menuCode3: '0000020',
			};
		} else if (sessionStorage.authCode > 1 && sessionStorage.appUse === 'Y') {
			param = {
				badgeType: 'CUST',
				userId: sessionStorage.userId,
				custMenuCode1: '0000005',
			};
		}

		getHeaderBadgeCount(param)
			.then((r) => {
				badgeList = r;
			})
			.catch((err) => {
				errorMsgCheck(err, this.activeProgress(false));
			});
	};

	getMenuList = () => {
		this.activeProgress(false);
		// 권한코드에 따른 메뉴조회
		let param = {
			authCode: sessionStorage.authCode,
			custNo: sessionStorage.custNo,
			systCode: sessionStorage.systCode,
		};
		getLoginMenuList(param)
			.then((r) => {
				r.map((item, idx) => {
					if (item.level === '0') {
						menuList1.push(item);
					} else if (item.level === '1') {
						menuList2.push(item);
					}
				});
				// 메뉴코드에따른 서브메뉴 map 생성
				let list = [];
				let customData = JSON.parse(sessionStorage.getItem('customData'));

				menuList1.map((item, idx) => {
					menuList2.map((item2, idx2) => {
						if(item2.menuCode == '0000192' && !customData.hasOwnProperty('CT039')) { // 고객사 전용 승계기능사용여부
							return;
						}
						if (item.menuCode === item2.upprMenu) {
							// spotTitle에 따른 지점호칭변경(지점/대리점/현장)
							if (item2.menuName.indexOf('지점') > -1) {
								item2.menuName = item2.menuName.replace('지점', sessionStorage.spotTitle);
							}
							if (customData != null && customData.hasOwnProperty('CT030') && item2.menuName.indexOf('HC자산관리') > -1) {
								item2.menuName = item2.menuName.replace('HC', customData['CT030']);
							}
							if (item2.realMenu !== null) {
								list.push(item2);
							}
						}
					});
					subMenu.set(item.menuCode, list);
					list = [];
				});

				this.activeProgress(false);
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => this.activeProgress(false));
			});
	};

	makeList = (cnt, menuCode) => {
		let list = subMenu.get(menuCode);
		let result = list.map((item, idx) => {
			if (idx < LINE_V * (cnt + 1) && idx >= LINE_V * cnt) {
				// 20181227 kkj 메뉴얼은 http로 시작하므로 분기생성하여 새탭으로 열기
				if (item.menuPath.indexOf('http') > -1) {
					return (
						<li key={idx} onClick={() => window.open(item.menuPath, '_blank')}>
							<Link to={'#'}>{item.menuName}</Link>
						</li>
					);
				} else {
					return (
						<li key={idx} onClick={() => this.clickSubMenu(menuCode, item.menuCode)}>
							<Link to={item.menuPath}>{item.menuName}</Link>
						</li>
					);
				}
			}
		});
		return <ul key={cnt + '_' + menuCode}>{result}</ul>;
	};
	makeUl = (menuCode) => {
		let list = [];
		for (let i = 0; i < LINE_H; i++) {
			list.push(this.makeList(i, menuCode));
		}
		return list;
	};
	activeMenu = (menuCode) => {
		let resultList = this.makeUl(menuCode);
		return (
			<div className="subGnb" style={{ display: this.state.menuCode === menuCode ? 'block' : 'none' }} onMouseLeave={() => this.hideSubMenu()}>
				{resultList.map((item, idx) => {
					return item;
				})}
			</div>
		);
		// if(menuCode === '0000002'){
		//     return(
		//         /* <RentalMenu />    */
		//         <div className="subGnb" style={{display: this.state.menuCode === menuCode ? 'block' : 'none'}}  onMouseLeave={()=> this.hideSubMenu()}>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000003')}><Link to={'/RentalOrder'}>렌탈신청</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000004')}><Link to={'/RentalOrderSearch'}>신청조회</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000005')}><Link to={'/RentalOrderAppr'}>결재</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000006')}><Link to={'/RentalStatusSearch'}>렌탈현황조회</Link></li>
		//             </ul>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000007')}><Link to={'/ReturnAsk'}>반납신청</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000008')}><Link to={'/ExpAssetSearch'}>만기자산관리</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000009')}><Link to={'/MonthEnd'}>월마감</Link></li>
		//             </ul>
		//         </div>
		//     );
		// }else if(menuCode === '0000010'){
		//     return(
		//         /* <CustPrvtMenu /> */
		//         <div className="subGnb" style={{display: this.state.menuCode === menuCode ? 'block' : 'none'}}  onMouseLeave={()=> this.hideSubMenu()}>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000011')}><Link to={'/PrntMntnCounter'}>복합기유지보수카운터</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000012')}><Link to={'/MG/MgPrntOrder'}>MG복합기신청</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000013')}><Link to={'/MG/MgPrntOrderSearch'}>MG복합기신청조회</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000014')}><Link to={'/MPC/MpcAssetManager'}>MPC자산관리</Link></li>
		//             </ul>
		//         </div>
		//     );
		// }else if(menuCode === '0000015'){
		//     return(
		//        /* <AdminMenu /> */
		//         <div className="subGnb" style={{display: this.state.menuCode === menuCode ? 'block' : 'none'}}  onMouseLeave={()=> this.hideSubMenu()}>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000016')}><Link to={'/ContractProc'}>계약생성</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000017')}><Link to={'/ReturnAsk'}>반납조회</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000018')}><Link to={'/CustManager'}>고객관리</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000019')}><Link to={'/CustItemManager'}>고객제품관리</Link></li>
		//             </ul>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000020')}><Link to={'/MemberAppr'}>가입요청승인</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000021')}><Link to={'/ContractTrans'}>계약승계</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000022')}><Link to={'/ErpDataSync'}>ERP렌탈신청동기화</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000023')}><Link to={'/MenuManager'}>메뉴관리</Link></li>
		//             </ul>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000024')}><Link to={'AuthManager'}>권한관리</Link></li>
		//             </ul>
		//         </div>
		//     );
		// }else if(menuCode === '0000025'){
		//     return(
		//        /* <MembersMenu /> */
		//        <div className="subGnb" style={{display: this.state.menuCode === menuCode ? 'block' : 'none'}}  onMouseLeave={()=> this.hideSubMenu()}>
		//         <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000026')}><Link to={'/MemberManager'}>사용자관리</Link></li>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000027')}><Link to={'/SpotManager'}>지점관리</Link></li>
		//         </ul>
		//         </div>
		//     );
		// }else if(menuCode === '0000028'){
		//     return(
		//         /* <PointMenu /> */
		//         <div className="subGnb" style={{display: this.state.menuCode === menuCode ? 'block' : 'none'}}  onMouseLeave={()=> this.hideSubMenu()}>
		//             <ul>
		//                 <li onClick={()=> this.clickSubMenu(menuCode, '0000029')}><Link to={'/PointSearch'}>포인트조회</Link></li>
		//             </ul>
		//         </div>
		//     );
		// }
	};

	showSubMenu = (menuCode) => {
		if (menuCode === 0) {
			this.setState({
				menuCode: '',
				subMenuTitle: 'none',
				subMenuBg: 'none',
			});
		} else {
			this.setState({
				menuCode: menuCode,
				subMenuTitle: 'block',
				subMenuBg: 'block',
			});
		}
	};

	clickSubMenu = (upprMenuCode, menuCode) => {
		getCustomDataList();
		comClickSubMenu(upprMenuCode, menuCode);
		this.hideSubMenu();
	};

	hideSubMenu = () => {
		this.setState({
			subMenuBg: 'none',
			menuCode: '',
		});
	};

	//로그아웃
	logoutConfirmOk = () => {
		// this.handleClose();
		let custNo = sessionStorage.custNo;
		let url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

		sessionStorage.clear(); // 세션스토리지 클리어 후 각 component에서 세션체크후 login으로 redirect해야함
		if (custNo === CUST_NO_HDEC) {
			//현대건설
			window.location.assign(url + '/HDEC');
		} else if (custNo === CUST_NO_HDENG) {
			//현대엔지니어링
			window.location.assign(url + '/HDENG');
		} else if (custNo === CUST_NO_DDI) {
			//두산디지털이노베이션
			window.location.assign(url + '/DDI');
		} else if (custNo === CUST_NO_HC || custNo === CUST_NO_HCL) {
			//한국코퍼레이션/한국클라우드
			window.location.assign(url + '/Hc');
		} else if (custNo === CUST_NO_HAS) {
			//홈앤쇼핑
			window.location.assign(url + '/HNS');
		} else if (custNo === CUST_NO_KMI) {
			//한국의학연구소
			window.location.assign(url + '/KMI');
		} else {
			
			// this.props.history.push('/Login', null);
			// window.location.href("http://localhost:3000/Login");
			// browserHistory.push('/Login');
			window.location.assign(url + '/Login');
		}
	};
	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	activeBadgeIcon = (menuCode) => {
		if (menuCode === '0000016') {
			return (
				<Tooltip title="계약대기건" placement="bottom">
					<ContIcon />
				</Tooltip>
			);
		} else if (menuCode === '0000017') {
			return (
				<Tooltip title="반납요청건" placement="bottom">
					<ReturnIcon />
				</Tooltip>
			);
		} else if (menuCode === '0000020') {
			return (
				<Tooltip title="가입승인요청건" placement="bottom">
					<PersonIcon />
				</Tooltip>
			);
		} else if (menuCode === '0000005') {
			return (
				<Tooltip title="나의 결재건" placement="bottom">
					<AppIcon />
				</Tooltip>
			);
		}
	};

	makeLogo = () => {
		if (this.state.custImg === '' || this.state.custImg === undefined) {
			return (
				<div className="h1Logo">
					<h1>
						<a href="/">
							<img src={ajLogo} alt="로고" />
						</a>
					</h1>
				</div>
			);
		} else {
			return (
				<div className="h1Logo1">
					<h1>
						<a href="/">
							<img src={this.state.custImg} alt="로고" />
						</a>
					</h1>
				</div>
			);
		}
	};

	render() {
		let isLoggedIn = sessionStorage.accessToken !== undefined ? true : false;

		if (isLoggedIn) {
			return (
				<div>
					<div className="head">
						<div className="inner">
							{/* <div className="h1Logo">
                                <h1><a href="/"><img src={ajLogo} alt='로고'/></a></h1>
                            </div> */}
							{this.makeLogo()}
							<div className="gnbList">
								<ul>
									{menuList1.map((item, i) =>
										i === 0 ? (
											<li
												key={item.menuCode}
												className={sessionStorage.activePageId == item.menuCode ? 'home active' : 'home'}
												onMouseOver={() => this.showSubMenu(0)}
											>
												<Link onClick={() => this.clickSubMenu(item.menuCode, item.menuCode)} to={'/Home'}>
													<span>홈</span>
												</Link>
											</li>
										) : (
											item.realMenu !== null && (
												<li
													key={item.menuCode}
													className={sessionStorage.activePageId == item.menuCode ? 'no' + i + ' active subUse' : 'no' + i + ' subUse'}
													onMouseOver={() => this.showSubMenu(item.menuCode)}
													onMouseOut={() => this.showSubMenu(0)}
												>
													<a>
														<span>{item.menuName}</span>
													</a>
													{this.activeMenu(item.menuCode)}
												</li>
											)
										),
									)}
								</ul>
							</div>
							<div className="rightInfo">
								<div className="nameWrap">
									<span>{sessionStorage.userName}</span>
									<em style={{ marginLeft: 5 }}>{sessionStorage.userId}</em> 고객님 환영합니다.
								</div>
								<div className="btnLogout">
									<a href="#" onClick={this.handleClickOpen} className="btn01 logout">
										<span>로그아웃</span>
									</a>
								</div>
								<div style={{ marginTop: 58, textAlign: 'right', marginRight: 87 }}>
									{sessionStorage.appUse === 'Y'
										? badgeList.map((item) => (
												<Link to={item.menuPath} key={item.menuCode}>
													<IconButton onClick={() => comClickSubMenu(item.upprMenu, item.menuCode)}>
														<Badge badgeContent={item.cnt} color="secondary" invisible={item.cnt > 0 ? false : true}>
															{this.activeBadgeIcon(item.menuCode)}
														</Badge>
													</IconButton>
												</Link>
										  ))
										: ''}
								</div>
							</div>
						</div>
						<div className="bgSub" style={{ display: this.state.subMenuBg }}></div>
					</div>
					<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="logoutTitle" aria-describedby="logoutDesc">
						<DialogTitle id="logoutTitle">{'로그아웃'}</DialogTitle>
						<DialogContent>
							<DialogContentText id="logoutDesc">로그아웃 하시겠습니까?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.logoutConfirmOk} color="primary" autoFocus>
								예
							</Button>
							<Button onClick={this.handleClose} color="primary">
								아니요
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}
