import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const AssetInfoChangePopStore = observable({
	open: false,
	asstInfo: {},
	handleClose: null,
	procType: 'change',

	openPopup(procType, asstInfo, handleClose) {
		this.open = true;
		this.asstInfo = asstInfo;
		if(typeof procType != 'undefined') {
			this.procType = procType
		}

		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		this.asstInfo = {};
		if (this.handleClose) {
			this.handleClose(item);
		}
	},
});

export default AssetInfoChangePopStore;
